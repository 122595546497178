import * as React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import HeroBanner from "../components/heroBanner"
import Grid4x1 from "../components/grid4x1"
import Offer1 from "../components/offer1"
import Offer2 from "../components/offer2"
import FeaturedProducts from "../components/featuredProducts"

const IndexPage = ({ data }) => (
  <Layout>
    <Seo title="Home" />
    <HeroBanner data={data.heroBanner.nodes} />
    <Grid4x1 data={data.grid4x1} />
    <Offer1 data={data.offer1} />
    <Offer2 data={data.offer2} />
    <FeaturedProducts data={data.featured_product}/>
  </Layout>
)

export const query = graphql`
  {
    heroBanner: allMasonGenericCreative(
      filter: { meta: { sectionName: { eq: "leaderboard_banner" } } }
      sort: { fields: created_time, order: DESC }
      limit: 1
    ) {
      nodes {
        meta {
          position
          sectionName
          sectionPosition
        }
        url
      }
    }
    grid4x1: allMasonGenericCreative(
      filter: { meta: { sectionName: { eq: "featured_categories" } } }
      sort: { fields: created_time, order: DESC }
    ) {
      nodes {
        meta {
          position
          sectionName
          sectionPosition
        }
        url
      }
      distinct(field: meta___position)
    }
    offer1: allMasonGenericCreative(
      filter: { meta: { sectionName: { eq: "offer_1" } } }
      sort: { fields: created_time, order: DESC }
    ) {
      nodes {
        meta {
          position
          sectionName
          sectionPosition
        }
        url
      }
      distinct(field: meta___position)
    }
    offer2: allMasonGenericCreative(
      filter: { meta: { sectionName: { eq: "offer_2" } } }
      sort: { fields: created_time, order: DESC }
    ) {
      nodes {
        meta {
         position
          sectionName
          sectionPosition
        }
        url
      }
      distinct(field: meta___position)
    }
    featured_product: allMasonGenericCreative(
      filter: { meta: { sectionName: { eq: "featured_product" } } }
      sort: { fields: created_time, order: DESC }
    ) {
      nodes {
        meta {
          position
          sectionName
          sectionPosition
        }
        url
      }
      distinct(field: meta___position)
    }
  }
`

export default IndexPage
